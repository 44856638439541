import React, { useEffect, useState } from 'react';

import DocumentMeta from 'react-document-meta';
import dayjs from 'dayjs';
import { Toast } from 'antd-mobile';
import Loading from '../components/loading';
import request from '../../shared/request';

const Record = props => {
  const { v } = props;
  const isRedeem = v.type === 'redeem';
  const icon = isRedeem ? (
    <i className="ri-exchange-fill" />
  ) : (
    <i className="ri-copper-coin-fill" />
  );
  return (
    <div className={`item ${isRedeem ? 'item-subtract' : 'item-add'}`}>
      {icon}
      <div className="nb nbfont">
        {isRedeem ? '-' : '+'}
        {v.points}
      </div>
      <div className="name">{v.name}</div>
      <div className="time">{dayjs(v.time).format('MM/DD hh:mm')}</div>
    </div>
  );
};

const Page = () => {
  const [list, setList] = useState(undefined);
  const getDetail = async () => {
    const { code, data } = await request.call('getPointsDetail');
    if (code === 0) {
      setList(data);
    } else {
      Toast.fail('数据加载失败 请重试');
    }
  };
  useEffect(() => {
    getDetail();
  }, []);
  const meta = {
    title: '积分明细',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper credits-wrapper">
        <section className="detail">
          <Loading condition={list}>
            {list && list.map(v => <Record key={v._id} v={v} />)}
          </Loading>
        </section>
      </div>
    </DocumentMeta>
  );
};

export default Page;
