import EventEmiter from 'events';

window.MiaoDispatch = {};

class WebBridge {
  async shareText(content) {
    console.log(content);
  }

  isMobile() {
    return window.MiaoApp || window.webkit;
  }

  open(url) {
    window.open(url);
  }

  viewAd() {
    console.log('view ad');
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 1);
    });
  }

  updateUser() {
    console.log('update User info');
  }

  getInfo() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: {
            device: 'web',
            mode: 'unknown',
            token: 'empty',
            version: '0.9.9',
            uuid: 'empty',
          },
        });
      }, 1);
    });
  }

  getLog() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: {
            url: '--',
          },
        });
      }, 1);
    });
  }
}
class Api {
  constructor() {
    this.event = new EventEmiter();
    window.MiaoDispatch.adRewardEarned = () => {
      this.event.emit('adViewed');
    };
    window.MiaoDispatch.getInfo = info => {
      this.event.emit('getInfo', info);
    };
    window.MiaoDispatch.getLog = info => {
      this.event.emit('getLog', info);
    };
  }

  isMobile() {
    return window.MiaoApp || window.webkit;
  }

  async shareText(content) {
    if (window.MiaoApp) {
      window.MiaoApp.shareText(content);
    } else {
      window.webkit.messageHandlers.shareText.postMessage(content);
    }
  }

  open(url) {
    if (window.MiaoApp) {
      window.MiaoApp.openWebView(encodeURI(url));
    } else {
      window.webkit.messageHandlers.openWebView.postMessage(encodeURI(url));
    }
  }

  getInfo() {
    if (window.MiaoApp) {
      window.MiaoApp.getInfo('MiaoDispatch.getInfo');
    } else {
      window.webkit.messageHandlers.getInfo.postMessage(
        'MiaoDispatch.getInfo',
      );
    }
    return new Promise(resolve => {
      this.event.on('getInfo', response => {
        resolve(response);
      });
    });
  }

  getLog() {
    if (window.MiaoApp) {
      window.MiaoApp.getLog('MiaoDispatch.getLog');
    } else {
      window.webkit.messageHandlers.getLog.postMessage('MiaoDispatch.getLog');
    }
    return new Promise(resolve => {
      this.event.on('getLog', response => {
        resolve(response);
      });
    });
  }

  updateUser() {
    if (window.MiaoApp) {
      window.MiaoApp.updateUser();
    } else {
      window.webkit.messageHandlers.updateUser.postMessage(null);
    }
  }

  viewAd() {
    if (window.MiaoApp) {
      window.MiaoApp.showAd();
    } else {
      window.webkit.messageHandlers.showAd.postMessage(null);
    }
    return new Promise(resolve => {
      this.event.on('adViewed', () => {
        resolve(true);
      });
    });
  }

  preloadAd() {
    if (window.MiaoApp) {
      window.MiaoApp.preloadAd();
    } else {
      window.webkit.messageHandlers.preloadAd.postMessage(null);
    }
  }
}

const native = window.MiaoApp || window.webkit ? new Api() : new WebBridge();
export default native;
