import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useDispatch } from 'redux-react-hook';
import CampaignCenter from './pages/campaign/center';
import CampaignShare from './pages/campaign/share';
import CampaignCredits from './pages/campaign/credits';
import CampaignCreditsDetail from './pages/campaign/credits-detail';
import CampaignCheckin from './pages/campaign/checkin';
import CampaignVip from './pages/campaign/vip';
import SupportCate from './pages/support/support-cate';
import SupportArticle from './pages/support/support-article';
import SupportTicket from './pages/support/support-ticket';
import SupportSuccess from './pages/support/support-success';
import Notification from './pages/notification/notification'

const AuthRoute = props => {
  const dispatch = useDispatch();

  const {
    location: { search },
  } = props;
  const queryParams = new URLSearchParams(search);
  const queryHeaders = {
    'X-Miao-Version': queryParams.get('version'),
    'X-Miao-App': queryParams.get('app'),
    'X-Miao-Token': queryParams.get('token'),
    'X-Miao-UUID': queryParams.get('uuid'),
    'X-Miao-Ch': queryParams.get('ch'),
  };
  const headers = {};
  Object.keys(queryHeaders).forEach(key => {
    if (queryHeaders[key]) {
      headers[key] = queryHeaders[key];
    }
  });
  dispatch({ type: 'headers/set', headers });
  if (queryParams.get('token')) {
    queryParams.delete('token');
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${queryParams.toString()}`,
    );
  }
  return <Route {...props} />;
};

const App = () => (
  <Router>
    <Switch>
      <AuthRoute path="/app" exact component={CampaignCenter} />
      <AuthRoute path="/app/center" exact component={CampaignCenter} />
      <AuthRoute path="/app/share" exact component={CampaignShare} />
      <AuthRoute path="/app/credits" exact component={CampaignCredits} />
      <AuthRoute path="/app/checkin" exact component={CampaignCheckin} />
      <AuthRoute path="/app/vip" exact component={CampaignVip} />
      <AuthRoute path="/app/support-cate" exact component={SupportCate} />
      <AuthRoute path="/app/support-article" exact component={SupportArticle} />
      <AuthRoute path="/app/support-ticket" exact component={SupportTicket} />
      <AuthRoute path="/app/support-success" exact component={SupportSuccess} />
      <AuthRoute path="/app/notification" exact component={Notification} />
      <AuthRoute
        path="/app/credits-detail"
        exact
        component={CampaignCreditsDetail}
      />
    </Switch>
  </Router>
);

export default App;
