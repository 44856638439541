import { createStore } from 'redux';
import produce from 'immer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';

const defaultState = {
  activities: [],
  referStats: {},
  headers: {},
};
const reducer = (state = defaultState, action) => {
  const freeze = produce(state, draft => {
    switch (action.type) {
      case 'headers/set':
        if (action.headers) {
          draft.headers = { ...draft.headers, ...action.headers };
        }
        break;
      case 'referstats/set':
        if (action.referStats) {
          draft.referStats = action.referStats;
        }
        break;
      case 'activities/set':
        if (action.activities) {
          draft.activities = action.activities;
        }
        break;
      case 'support/set':
        if (action.cate) {
          const { cid, title } = action.cate;
          draft.support = {
            cid,
            title,
          };
        }
        break;
      default:
        break;
    }
    return draft;
  });
  return { ...freeze };
};

const persistConfig = {
  key: 'miao-app-redux',
  storage,
  stateReconciler: hardSet,
  serialize: true,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
