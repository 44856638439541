import React from 'react';

const SupportHeader = props => (
  <div className="support-footer">
    <div className="support-container footer">
      <div className="left">
        <span>© 2019-2020 加速喵. All rights reserved.</span>
      </div>
      <div className="right-nav">
        <a href="https://miaovpn.com/support" target="_blank" rel="noreferrer">帮助中心</a>
        <a href="https://miaovpn.com/changelog" target="_blank" rel="noreferrer">版本日志</a>
        <a href="https://miaovpn.com/" target="_blank" rel="noreferrer">官网</a>
      </div>
    </div>
  </div>
);

export default SupportHeader;
