import React from 'react';
import ReactDOM from 'react-dom';
import { StoreContext } from 'redux-react-hook';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './shared/store';
import './styles/main.scss';
import 'remixicon/fonts/remixicon.css';
import App from './App';

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </StoreContext.Provider>,
  document.getElementById('root'),
);
