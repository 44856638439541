import React from 'react';
import DocumentMeta from 'react-document-meta';

const Center = () => {
  const meta = {
    title: '签到',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper checkin-wrapper">
        <h4>第一步：关注公众号</h4>
        <p>微信搜索「Malus加速器」关注公众号</p>
        <h4>第二步：绑定账号</h4>
        <p>点击微信公众号菜单「我的」 - 「绑定微信」</p>
        <img src="https://malus.s3cdn.net/uploads/wechat_tips3.png" alt="" />
        <h4>第三步：签到领积分</h4>
        <p>微信公众号内回复「签到」即可成功领取1积分</p>
        <img src="https://malus.s3cdn.net/uploads/wetips2.png" alt="" />
      </div>
    </DocumentMeta>
  );
};

export default Center;
