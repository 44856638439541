import React, { useState } from 'react';
import DocumentMeta from 'react-document-meta';

const Center = () => {
  const meta = {
    title: '会员权益对比表',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  const [tab, setTab] = useState(1);

  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper vip">
        <div className="tab">
          <div
            className={`item-tab ${tab === 1 ? 'active' : ''}`}
            onClick={_ => setTab(1)}>
            <img
              src="https://miao.s3cdn.net/uploads/miao_free_badge.svg"
              alt="free"
            />
            <span>非会员</span>
          </div>
          <div
            className={`item-tab base ${tab === 2 ? 'active' : ''}`}
            onClick={_ => setTab(2)}>
            <img
              src="https://miao.s3cdn.net/uploads/miao_turbo_badge.svg"
              alt="free"
            />
            <span>标准会员</span>
          </div>
          <div
            className={`item-tab game ${tab === 3 ? 'active' : ''}`}
            onClick={_ => setTab(3)}>
            <img
              src="https://miao.s3cdn.net/uploads/miao_primium_badge.svg"
              alt="free"
            />
            <span>高级会员</span>
          </div>
        </div>
        <div className="tab-body">
          {tab === 1 && (
            <div className="content">
              <div className="tr">
                <i className="ri-broadcast-line" />
                <div className="title">回国通道</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right false">
                  <i className="ri-close-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-landscape-line" />
                <div className="title">多模式切换</div>
                <div className="right false">
                  <i className="ri-close-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right false">
                  <i className="ri-close-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">回国线路</div>
                <div className="right true">普通</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">带宽速度</div>
                <div className="right true">标清</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">72小时</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备在线</div>
                <div className="right true">1</div>
              </div>
            </div>
          )}
          {tab === 2 && (
            <div className="content">
              <div className="tr">
                <i className="ri-broadcast-line" />
                <div className="title">回国通道</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-landscape-line" />
                <div className="title">多模式切换</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right false">
                  <i className="ri-close-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">回国线路</div>
                <div className="right true">精品</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">带宽速度</div>
                <div className="right true">高清</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">优先</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备在线</div>
                <div className="right true">3</div>
              </div>
            </div>
          )}
          {tab === 3 && (
            <div className="content">
              <div className="tr">
                <i className="ri-vip-crown-2-line" />
                <div className="title game">包含标准会员权益</div>
                <div className="right true"></div>
              </div>
              <div className="tr">
                <i className="ri-broadcast-line" />
                <div className="title">回国通道</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-landscape-line" />
                <div className="title">多模式切换</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right true">
                  <i className="ri-checkbox-circle-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">回国线路</div>
                <div className="right true">专线</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">带宽速度</div>
                <div className="right true">高清 + 低延迟</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">高级优先</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备在线</div>
                <div className="right true">3</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Center;
