import { store } from './store';

const API_ENDPOINT = 'https://miaovpn.com/api';
// const API_ENDPOINT = 'https://stg-401.miaovpn.com/api';
// const API_ENDPOINT = 'http://miao.localhost:5000/api';

export default class Request {
  static async post(fn, payload, customHeaders) {
    const defaultHeaders = {
      credentials: 'include',
      'Content-Type': 'application/json',
    };
    const state = store.getState();
    if (state.headers) {
      Object.keys(state.headers).forEach(k => {
        if (k && state.headers[k]) {
          defaultHeaders[k] = state.headers[k];
        }
      });
    }
    try {
      const user = JSON.parse(window.localStorage.getItem('login'));
      if (user && user.token) {
        defaultHeaders['X-Miao-Token'] = user.token;
      }
    } catch (error) {
      // noop
    }
    const headers = customHeaders
      ? { ...defaultHeaders, ...customHeaders }
      : defaultHeaders;
    let body = '{}';
    if (payload) {
      if (typeof payload !== 'object') {
        // eslint-disable-next-line
        console.warn(
          `WARN Request.${fn} input payload must be a Object but incoming ${payload}`,
        );
      }
      body = JSON.stringify(payload);
    }
    const response = await fetch(`${API_ENDPOINT}/${fn}`, {
      method: 'POST',
      headers,
      body,
    });
    let json = {};
    try {
      json = await response.json();
    } catch (err) {
      json = {
        code: 1,
        message: err.message || `${fn} Unexpected end of JSON input`,
      };
    }
    return json;
  }

  static call(fn, payload) {
    return Request.post(fn, payload);
  }
}
