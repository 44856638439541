import React, { useState, useEffect, useCallback } from 'react';
import DocumentMeta from 'react-document-meta';
import { Toast } from 'antd-mobile';
import { useDispatch, useMappedState } from 'redux-react-hook';
import BottomSheet from '../components/bottom-sheet';
import Loading from '../components/loading';
import request from '../../shared/request';
import native from '../../shared/native';

const Center = props => {
  const dispatch = useDispatch();
  const state = useMappedState(useCallback(root => root), []);

  const [show, setShow] = useState(false);
  const [ov, setOv] = useState({ total: '-' });
  const [stats, setStats] = useState({ used: 0, total: 0 });

  const init = async () => {
    const { code, data, message } = await request.call('getPointsStatus');
    if (code === 0) {
      setOv(clone(data));
      updateStats(data);
      native.preloadAd();
    } else {
      Toast.fail(message);
    }
  };

  const updateStats = data => {
    let total = 0;
    let used = 0;
    if (data.tasks) {
      data.tasks.forEach(v => {
        total = v.limit * v.points + total;
        used = v.used * v.points + used;
      });
    }
    setStats(clone({ total, used }));
  };

  const clone = obj => {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch (error) {
      return obj;
    }
  };

  const redeem = async item => {
    const { code, data, message } = await request.call('redeemPoints', {
      pid: item.id,
    });
    if (code === 0) {
      Toast.success(data.message);
      const { total } = data;
      ov.total = total;
      setOv(clone(ov));
      updateStats(ov);
      native.updateUser();
    } else {
      Toast.fail(message);
    }
  };

  const getReferStats = async () => {
    const response = await request.call('getReferStats', { valid: true });
    if (response.code === 0) {
      dispatch({ type: 'referstats/set', referStats: response.data });
    }
  };

  const share = async () => {
    let shareTxt =
      '我在用加速喵回国加速，在海外一键加速中国影音应用最高可到4k，也可加速游戏国服低延迟、不丢包。https://getmalus.com/start/$code';
    if (state.referStats.referCode) {
      shareTxt = shareTxt.replace('$code', state.referStats.referCode);
    } else {
      await getReferStats();
      shareTxt = shareTxt.replace('$code', state.referStats.referCode);
    }
    await native.shareText(shareTxt);
    return true;
  };

  const abtain = async item => {
    if (item.url) {
      props.history.push(item.url);
      return;
    }
    if (item.id === 'viewad') {
      await native.viewAd();
    }
    const isShare = item.id === 'share';
    if (isShare) {
      await share();
    }
    const { code, data, message } = await request.call('postPoints', {
      tid: item.id,
    });
    if (code === 0) {
      if (!isShare) {
        Toast.success(data.message);
      }
      const { total, task } = data;
      ov.total = total;
      ov.tasks.forEach((v, index) => {
        if (v.id === task.id) {
          ov.tasks[index] = task;
        }
      });
      setOv(clone(ov));
      updateStats(ov);
    } else {
      Toast.fail(message);
    }
  };
  useEffect(() => {
    init();
    getReferStats();
  }, []);
  const meta = {
    title: '积分中心',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <BottomSheet title="积分规则" show={show} close={() => setShow(false)}>
        <ol>
          <li>用户需注册后参与积分任务</li>
          <li>每日签到需要用户关注“malus加速器”公众号</li>
          <li>每次必须完成看完一次广告才可成功领取积分</li>
          <li>
            积分有效期：积分有效期为获取之日起至次年的6月1日。如在2019年12月3日获得的10积分，将在2020年6月1日过期清零。（这里的时间皆为北京时间）
          </li>
          <li>如发现有作弊行为，Malus有权直接取消相应积分。</li>
          <li>本活动解释权归Malus所有。</li>
          <li>所有兑换奖品与活动与苹果公司无关</li>
        </ol>
      </BottomSheet>
      <div className="content-wrapper credits-wrapper">
        <section className="credit">
          <div className="cards">
            <div className="nb nbfont">{ov.total}</div>
            <span>我的积分</span>
          </div>
          <div className="cards">
            <div className="nb nbfont">
              {stats.used}
              <small>/{stats.total}</small>
            </div>
            <span>今日领取</span>
          </div>
        </section>
        <section className="tasks">
          <div className="title">
            <h4>每日积分任务</h4>
            <span onClick={() => props.history.push('/app/credits-detail')}>
              积分明细
            </span>
          </div>
          <Loading condition={ov && ov.tasks}>
            {ov &&
              ov.tasks &&
              ov.tasks.map(v => (
                <div className="item" key={v.id}>
                  <i className="ri-copper-coin-fill" />
                  <div className="nb nbfont">
                    +<span>{v.points}</span>
                  </div>
                  <span className="name">{v.name}</span>
                  <span className="progress">
                    {v.used}/{v.limit}
                  </span>
                  <div
                    className={`btn ${
                      v.used >= v.limit ? 'btn-disable' : 'btn-default'
                    }`}
                    onClick={() => v.used < v.limit && abtain(v)}>
                    {v.button}
                  </div>
                </div>
              ))}
          </Loading>
        </section>
        <section className="exchange">
          <div className="title">
            <h4>积分兑换</h4>
            <span onClick={() => setShow(true)}>积分规则</span>
          </div>
          <Loading condition={ov && ov.tasks}>
            {ov &&
              ov.products &&
              ov.products.map(v => (
                <div key={v.id} className="item">
                  <div className="name">{v.name}</div>
                  <i className="ri-exchange-fill" />
                  <div className="nb">{v.points}积分</div>
                  <div
                    className={`btn ${
                      ov.total < v.points ? 'btn-disable' : 'xxx'
                    }`}
                    onClick={() => ov.total >= v.points && redeem(v)}>
                    立即兑换
                  </div>
                </div>
              ))}
          </Loading>
        </section>
      </div>
    </DocumentMeta>
  );
};

export default Center;
