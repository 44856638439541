import React from 'react';

const SupportFooter = props => (
  <div className="support-header">
    <div className="support-container header-nav">
      <a className="brand" href="/app/support-cate">
        <img src="https://miao.s3cdn.net/uploads/miao_email_logo.svg" alt="logo" />
        <span className="sub-title">提交反馈</span>
      </a>
      <div className="nav-link">
        <a href="https://miaovpn.zendesk.com/hc/zh-cn/requests" class="btn-ticket">
          <i className="ri-file-shield-line"></i>
          <span>我的工单</span>
        </a>
        <a className="btn-dashboard" href="https://miaovpn.com/dashboard">
          <i className="ri-user-fill"></i>
          <span>个人中心</span>
        </a>
      </div>
    </div>
  </div>
);

export default SupportFooter;
