import React, { useEffect, useCallback } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { Toast } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import Native from '../../shared/native';

const Share = () => {
  const dispatch = useDispatch();
  const state = useMappedState(useCallback(root => root), []);
  const getReferStats = async () => {
    const response = await request.call('getReferStats', { valid: true });
    if (response.code === 0) {
      dispatch({ type: 'referstats/set', referStats: response.data });
    }
  };
  const share = async () => {
    let shareTxt =
      '和我一起使用加速喵回国加速器吧，看视频、听音乐、玩游戏、做直播全部都支持。https://miaovpn.com/start/$code';

    if (state.referStats.referCode) {
      shareTxt = shareTxt.replace('$code', state.referStats.referCode);
      await Native.shareText(shareTxt);
    } else {
      Toast.fail('邀请码未能获取成功，请尝试重新打开');
    }
  };
  useEffect(() => {
    getReferStats();
  }, []);
  const meta = {
    title: '分享送会员',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper">
        <div className="sharenum">
          <div className="item">
            <i className="ri-map-pin-user-fill" />
            <span>已邀请人数</span>
            <h3>{state.referStats.referCount || '0'}</h3>
          </div>
          <div className="item">
            <i className="ri-map-pin-time-line" />
            <span>已获赠天数</span>
            <h3>{state.referStats.referDays || '0'}</h3>
          </div>
        </div>
        <div className="share-rule">
          <h3>活动规则</h3>
          <ol>
            <li>邀请好友注册加速喵，成功后可获得3天VIP</li>
            <li>单一IP最多允许 3 个邀请注册，超出不再奖励</li>
          </ol>
        </div>
        <div className="share-btn" onClick={share}>
          <i className="ri-share-forward-box-fill" />
          <span>立即分享，获赠 VIP</span>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default Share;
