import React, { useEffect, useState } from 'react';
import { Progress } from 'antd-mobile';
import dayjs from 'dayjs';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';
import If from '../components/if';

const Notification = () => {
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  const [messages, setMessages] = useState([]);
  const getMessages = async () => {
    const response = await request.call('getPushMessages', {});
    if (response.code === 0) {
      setMessages(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setPercent(o => (o < 80 ? o + 10 : o));
    }, 300);
    getMessages();
    return () => {
      clearInterval(timer);
    };
  }, []);
  const meta = {
    title: '消息中心',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper notification">
        <If condition={loading}>
          <Progress
            percent={percent}
            position="fixed"
            style={{ background: '#303061b0' }}
            barStyle={{ border: '2px solid #a1a1a5' }}
          />
        </If>
        <If condition={!loading}>
          <If condition={messages && messages.length > 0}>
            {messages.map(message => (
              <div
                onClick={() => message.link && native.open(message.link)}
                className={`item ${!message.link ? 'no-link' : ''}`}>
                <If condition={message.img}>
                  <img src={message.img} alt="" />
                </If>

                <div className="title">{message.title}</div>
                <div className="des">{message.desc}</div>
                <div className="link">
                  <div className="time">
                    {dayjs(message.pushAt).format('YYYY.MM.DD')}
                  </div>
                  <i className="ri-arrow-drop-right-line" />
                </div>
              </div>
            ))}
          </If>
          <If condition={messages && messages.length < 1}>
            <div className="message-empty">喵~暂无消息</div>
          </If>
        </If>
      </div>
    </DocumentMeta>
  );
};
export default Notification;
