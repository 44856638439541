import React, { useEffect, useState, useCallback } from 'react';
import { Toast } from 'antd-mobile';
import { useMappedState } from 'redux-react-hook';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';
import If from '../components/if';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';

const searchToObject = () => {
  const pairs = window.location.search.substring(1).split('&');
  const obj = {};
  let pair;
  Object.keys(pairs).map(i => {
    if (pairs[i] !== '') {
      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  });
  return obj;
};

const SupportTicket = props => {
  const [loading, setLoading] = useState(true);
  const {
    support: { cid, title },
  } = useMappedState(useCallback(state => state, []));
  const [form, setForm] = useState([]);
  const [formData, setFormData] = useState({});
  const updateForm = (k, value) => {
    const formClone = JSON.parse(JSON.stringify(formData));
    formClone[k] = value;
    setFormData(formClone);
  };

  const [subminting, setSubmiting] = useState(false);
  const submit = async () => {
    const required = form.find(
      v => v.required === 'TRUE' && v.name && !formData[v.name],
    );
    if (required) {
      return Toast.fail(required.error, 1);
    }
    if (subminting) {
      return Toast.info('正在反馈中...', 1);
    }
    setSubmiting(true);
    let appData = {};
    const queryData = searchToObject();
    const log = await native.getLog();
    if (log.data && log.data.url) {
      appData = { ...appData, log: log.data.url };
    }
    const info = await native.getInfo();
    if (info && info.data) {
      appData = { ...appData, ...info.data };
    }
    if (info.error) {
      setSubmiting(false);
      return Toast.fail(info.error || '信息获取异常', 1);
    }
    const response = await request.call('createTicket', {
      supportCate: title,
      ...appData,
      ...queryData,
      ...formData,
    });
    setSubmiting(false);
    if (response.code === 0) {
      return props.history.replace('/app/support-success');
    }
    return Toast.fail(response.message || '反馈失败', 1);
  };
  const getSupportForm = async () => {
    const response = await request.call('getSupportForm', { cid });
    if (response.code === 0 && response.data) {
      setForm(response.data);
      response.data.map(item => {
        if (item.type === 'select') {
          item.options.map(option => {
            if (option.default === 'TRUE') {
              const data = {};
              data[item.name] = option.title;
              setFormData(data);
            }
          });
        }
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    getSupportForm();
  }, []);
  const back = () => {
    props.history.replace('/app/support-article');
  };
  const meta = {
    title: '提交反馈',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="navbar" onClick={back}>
          <div className="back">
            <i className="ri-arrow-left-circle-line" />
            <span>返回</span>
          </div>
          <span className="title">提交反馈</span>
        </div>
        <div className="content support-ticket-content">
          <div className={`form ${loading ? 'block-loading' : ''}`}>
            <div className="loading">
              <i className="ri-loader-4-line" />
            </div>
            {form.map(el => {
              if (el.type === 'text') {
                return (
                  <div className="form-group">
                    <label>{el.label}</label>
                    <input
                      type="text"
                      onChange={e => updateForm(el.name, e.target.value)}
                      placeholder={el.placeholder}
                    />
                  </div>
                );
              }
              if (el.type === 'textarea') {
                return (
                  <div className="form-group">
                    <label>{el.label}</label>
                    <textarea
                      name={el.name}
                      onChange={e => updateForm(el.name, e.target.value)}
                      placeholder={el.placeholder}
                    />
                  </div>
                );
              }
              if (el.type === 'select') {
                return (
                  <div className="form-group">
                    <label>{el.label}</label>
                    <select onChange={e => updateForm(el.name, e.target.value)}>
                      {el.options.map(option => (
                        <option
                          value={option.title}
                          selected={option.default === 'TRUE'}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                    <i className="ri-arrow-down-s-line" />
                  </div>
                );
              }
            })}
            <If condition={!loading}>
              <button
                onClick={e => submit()}
                className={`${subminting ? 'loading' : ''}`}>
                {subminting ? '反馈中...' : '发送反馈'}
              </button>
            </If>
          </div>
        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportTicket;
